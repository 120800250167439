export interface IRoute {
  path: string,
  fullUrl: string
}

export const DASHBOARD: IRoute = {
  path: 'dashboard',
  get fullUrl(): string {
    return `/${this.path}`;
  }
};

export const ORGANIZATIONS: IRoute = {
  path: 'organizations',
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  }
};

export const ORDERS: IRoute = {
  path: 'orders',
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  }
}

export const ORDER_DETAILS: IRoute = {
  path: 'order-details',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`;
  }
};

export const ORDER_REFUNDS: IRoute = {
  path: 'order-refund',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`;
  }
};

export const EVENT_ORDER_DETAILS: IRoute = {
  path: 'event-order-details',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`;
  }
};

export const ORDERED_TICKET_REFUND_ISSUE: IRoute = {
  path: 'issue-refunds',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`
  }
}

export const EVENT_ORDERS_ISSUE_REFUND: IRoute = {
  path: 'event-orders-issue-refunds',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`
  }
}

export const ISSUE_EVENT_REFUND: IRoute = {
  path: 'issue-event-refund',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`
  }
}
export const CONFIRM_EVENT_REFUND: IRoute = {
  path: 'confirm-event-refund',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`
  }
}

export const EVENT_TICKETS_ISSUE_REFUND: IRoute = {
  path: 'event-tickets-issue-refunds',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`
  }
}

export const DECLINE_REFUND: IRoute = {
  path: 'decline-refunds',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`
  }
}


export const CHECK_IN_AND_SCANNING: IRoute = {
  path: 'check-in-and-scanning',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`
  }
}

export const CONFIRM_DECLINE_REFUND: IRoute = {
  path: 'confirm-refund-decline',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`
  }
}

export const VIEW_TICKET: IRoute = {
  path: 'view-tickets',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`
  }
}

export const CONFIRM_REFUND: IRoute = {
  path: 'confirm-refund',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`
  }
}

export const CONFIRM_EVENT_ORDER_REFUND: IRoute = {
  path: 'confirm-event-order-refund',
  get fullUrl(): string {
    return `${ORDERS.fullUrl}/${this.path}`
  }
}

/**
 * ORGANIZATION_ROUTES
 */

export const ORGANIZATIONS_DETAILS: IRoute = {
  path: 'organizations-details',
  get fullUrl(): string {
    return `${ORGANIZATIONS.fullUrl}/${this.path}`;
  }
};

export const ADD_ORGANIZATION: IRoute = {
  path: 'add-organization',
  get fullUrl(): string {
    return `${ORGANIZATIONS.fullUrl}/${this.path}`;
  }
};

export const ORGANIZATIONS_USER_DETAILS: IRoute = {
  path: 'user-details',
  get fullUrl(): string {
    return `${ORGANIZATIONS.fullUrl}/${this.path}`;
  }
};

export const INVITE_USER: IRoute = {
  path: 'invite-user',
  get fullUrl(): string {
    return `${ORGANIZATIONS.fullUrl}/${this.path}`;
  }
};

export const EDIT_ORGANIZATION: IRoute = {
  path: 'edit-organization',
  get fullUrl(): string {
    return `${ORGANIZATIONS.fullUrl}/${this.path}`;
  }
};


export const TAG_MANAGEMENT: IRoute = {
  path: 'tag-management',
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  }
};


export const MANAGE_BANNER: IRoute = {
  path: 'banner-management',
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  }
}
export const ADD_BANNER: IRoute = {
  path: 'add-banner',
  get fullUrl(): string {
    return `${MANAGE_BANNER.fullUrl}/${this.path}`;
  }
}
export const Edit_BANNER: IRoute = {
  path: 'edit-banner',
  get fullUrl(): string {
    return `${MANAGE_BANNER.fullUrl}/${this.path}`;
  }
}
export const MANAGE_EVENT: IRoute = {
  path: 'event-management',
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  }
}

export const ORGANIZATION_EVENTS: IRoute = {
  path: 'organization-events',
  get fullUrl(): string {
    return `${MANAGE_EVENT.fullUrl}/${this.path}`;
  }
}

export const EVENT_DETAIL: IRoute = {
  path: 'event-details',
  get fullUrl(): string {
    return `${MANAGE_EVENT.fullUrl}/${this.path}`;
  }
}
export const EVENT_CLASS_DETAIL: IRoute = {
  path: 'event-class-details',
  get fullUrl(): string {
    return `${MANAGE_EVENT.fullUrl}/${this.path}`;
  }
}
export const EVENT_ATTENDEES: IRoute = {
  path: 'event-attendees',
  get fullUrl(): string {
    return `${MANAGE_EVENT.fullUrl}/${this.path}`;
  }
}
export const CREATE_EVENT: IRoute = {
  path: 'create-event',
  get fullUrl(): string {
    return `${MANAGE_EVENT.fullUrl}/${this.path}`;
  }
}
export const CLASS_CREATOR_EVENT: IRoute = {
  path: 'class-creator-event',
  get fullUrl(): string {
    return `${MANAGE_EVENT.fullUrl}/${this.path}`;
  }
}
export const EDIT_EVENT: IRoute = {
  path: 'edit-event',
  get fullUrl(): string {
    return `${MANAGE_EVENT.fullUrl}/${this.path}`;
  }
}
export const EDIT_CLASS_EVENT: IRoute = {
  path: 'edit-class-event',
  get fullUrl(): string {
    return `${MANAGE_EVENT.fullUrl}/${this.path}`;
  }
}
export const ACCOUNT: IRoute = {
  path: '',
  get fullUrl(): string {
    return `${this.path}`;
  }
};

export const ADMIN: IRoute = {
  path: '',
  get fullUrl(): string {
    return `${this.path}`;
  }
};

export const LOGIN: IRoute = {
  path: 'login',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  }
};

export const FORGOT_PASSWORD: IRoute = {
  path: 'forgot-password',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  }
};

export const RESET_PASSWORD: IRoute = {
  path: 'reset-password',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  }
};
export const OTP_VERIFICATION: IRoute = {
  path: 'otp-verification',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  }
};

export const RESET_LINK: IRoute = {
  path: 'reset-link',
  get fullUrl(): string {
    return `${this.path}`;
  }
};

export const MY_PROFILE: IRoute = {
  path: 'my-profile',
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  }
};

export const CHANGE_PASSWORD: IRoute = {
  path: 'change-password',
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  }
};


export const DEEP_LINK: IRoute = {
  path: 'deeplink',
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  }
};







export const USER_MANAGEMENT: IRoute = {
  path: 'user-management',
  get fullUrl() {
    return `${ADMIN.fullUrl}/${this.path}`
  }
}


export const USER_DETAIL: IRoute = {
  path: 'user-detail',
  get fullUrl() {
    return `${USER_MANAGEMENT.fullUrl}/${this.path}`
  }
}

export const BASIC_PROFILE: IRoute = {
  path: 'basic-profile',
  get fullUrl() {
    return `${USER_DETAIL.fullUrl}/${this.path}`
  }
}

export const EVENTS_BOOKED: IRoute = {
  path: 'events-booked',
  get fullUrl() {
    return `${USER_DETAIL.fullUrl}/${this.path}`
  }
}
export const BOOKED_EVENT_DETAILS: IRoute = {
  path: 'booked-event-details',
  get fullUrl() {
    return `${USER_DETAIL.fullUrl}/${this.path}`
  }
}

export const CREATED_EVENTS: IRoute = {
  path: 'created-events',
  get fullUrl() {
    return `${USER_DETAIL.fullUrl}/${this.path}`
  }
}


