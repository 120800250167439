import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './services/interceptor';
import { LoaderModule } from './components/loader/loader.module';
import { ToastNotificationModule } from './components/toast-notification/toast-notification.module';
import { RouterModule, Routes } from '@angular/router';
import { ADMIN, ACCOUNT, DEEP_LINK } from './constants/routes';
import { AuthGuard } from './services/guards/auth-guard/auth.guard';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { SelectivePreloadingStrategy } from './components/pre-load-strategy/selective-preload-strategy';

const appRoutes: Routes = [
  { path: '', redirectTo: ADMIN.path, pathMatch: 'full' },
  {
    path: ACCOUNT.path,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: ADMIN.path,
    data:{preload:true},
    loadChildren: () =>
      import('./modules/layout/layout.module').then((m) => m.LayoutModule),
      canActivate:[AuthGuard]

  },
  {
    path: DEEP_LINK.path,
    loadChildren: () =>
      import('./modules/deep-link/deep-link.module').then(
        (m) => m.DeepLinkModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },

];
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, {preloadingStrategy:SelectivePreloadingStrategy}),
    BrowserAnimationsModule,
    HttpClientModule,
    LoaderModule,
    ToastNotificationModule,
  ],

  
  providers: [
    httpInterceptorProviders,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
             '809263833855-1ekao61ndv5suds36p4ulmvtius14pgk.apps.googleusercontent.com'
            )
            },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              '9885FD35K7'
            )
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
      },
    SelectivePreloadingStrategy,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
